.contact_fluid{
    background-color: #f2f2f4;
    padding-top: 1rem;
    padding-bottom: 1rem;
    margin-top: 1rem;
}
.contact{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 5vh;
    margin-bottom: 5vh;
}

.contact_img{
    width: 48%!important;
    background-image: url('./img/contact.jpg');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}
.contact_form{
    width: 48%;
}
.contact h3{
    margin-bottom: 2rem;
}
.contact form{
    background-color: var(--white);
}
.form-floating input{
    padding-top: 2.5rem!important;
    padding-bottom: 1rem!important;
}
@media screen and (max-width: 590px)  {
    .contact_img{
        display: none;
    }
    .contact_form{
        width: 100%;
    }
}