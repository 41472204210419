.cover{
    height: 80vh;
    display: flex;
    justify-content: space-between!important;
    align-items: center!important;
    background-color: var(--pal_gray);
    background-image: url('./img/back.jpg');
    background-size: contain;
    background-position: right;
    background-repeat: no-repeat;
}
.cover_text h1{
    margin-bottom: 3rem;
}
@media screen and (max-width: 1350px)  {
    .cover{
        background-size: contain;
        background-position-x: 150%;
        background-position-y: center;
    }
}
@media screen and (max-width: 1170px)  {
    .cover{
        background-size: contain;
        background-position-x: 200%;
        background-position-y: center;
    }
}
@media screen and (max-width: 990px)  {
    .cover{
        height : 90vh;
        background-size: cover;
        background-position-x: 0;
        background-position-y: center;
    }
    .cover_text{
        width : 100%;
    }
}