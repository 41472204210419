.back_office{
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: space-around;
}
.form-check{
    display: inline-block!important;
}
.articles_list{
    width: 22%;
    height: 98vh;
    border: solid 1px #AAA;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: column;
}
.articles_list_block{
    width: 100%;
    height: 90vh;
    border-bottom: solid 1px #EEE;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
}
.article_list_line{
    width: 90%;
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: solid 1px #eee;
}
.article_list_line_name{
    padding: 0px 5px;
    width: 60%;
    overflow: hidden;
    font-size: 1rem;
    height: 1.5rem;
}
.article_zone{
    width: 50%;
    height: 98vh;
    padding: 5px;
    border: solid 1px #AAA;
    border-radius: 4px;
    text-align: center;
}
.order_zone{
    width: 25%;
    height: 98vh;
    border: solid 1px #AAA;
    border-radius: 4px;
    display: flex;
    align-items: center;
    flex-direction: column;
    overflow-x: hidden;
    overflow-y: auto;
}
.order_day_block{
    width: 98%;
    margin: 5px 3px;
    padding: 5px;
    margin: 3px;
    background-color: #DDD;
    border-radius: 5px;
    border: solid 1px #AAA;
}
.order_day_date{
    width: 100%;
    padding: 5px;
    color: #444; 
}
.order_day_articles{
    width: 96%;
    padding: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.order_day_articles ul{
    padding: 0;
    width: 98%;
}
.order_day_client_line{
    display: inline-block;
    border-bottom: solid 1px #DDD;
    padding: 3px 0px;
    width: 100%;
    text-align: center;
    color: #111;
}
.order_day_article_line{
    width: 95%;
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    padding: 3px 0px;
    text-align: center;
    color: #555;
}
.order_valid_button {
    width : 100%;
}
.order_day_client_line span{
    display: inline-block;
    width : 100%;
}
.order_day_article_line span{
    display: inline-block;
    width : 100%;
}

.bo_article_image_zone{
    margin: 5px;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    border-radius: 4px;
    background-color: #EEE;
    border: solid 1px #AAA;
}
.bo_article_images{
    width: 100%;
    max-height: 25vh;
    display: flex;
    align-items: flex-start;
    justify-content: left;
    margin-bottom: 10px;
}
.bo_article_image{
    width : 12vw;
    height: 12vw;
    background-color: #222;
    cursor : pointer;
    display: flex;
    padding: 5px;
    align-items: flex-end;
    justify-content: center;
    background-size: cover;
    background-position: center;
    border-radius: 3px;
}
.bo_article_image_delete{
    display : none;
}
.back_office_connec{
    width: 35%;
    padding: 10px;
    font-family: "Poppins", sans-serif;
    border: solid 1px #DDD;
    border-radius: 4px;
    box-shadow: 0px 0px 5px #5f735d;
    background-color: #FFF;
}
.green_background{
    background-color: #86A083;
}