.ArticleCartLine{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 0;
    border-bottom: solid 1px var(--dark-gray);
}
.article_cart_line_img{
    width: 5rem!important;
    object-fit: contain;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
}
.article_cart_line_price{
    color: var(--dark-gray);
}