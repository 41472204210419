.ShopTop{
    background-color: var(--pal_gray);
    border-top: solid 3px var(--green);
}
.ShopTopText {
    width: 15rem;
    text-align: left;
}
.ShopTop h3{
    display: block;
    background-color: var(--green);
    color: #FFF;
    font-size: 2rem;
    padding: 0.5rem;
    width: 25%;
    text-align: left;
    margin-top: -1.5rem;
}
.ShopTopText h4{
    margin-bottom: 3rem;
}
.ShopTopArticles{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    padding-bottom: 3rem;
    margin-top: 2rem;
}
@media screen and (max-width: 770px)  {
    .ShopTop h3{
        width: auto;
    }
}
@media screen and (max-width: 479px)  {
    .ShopTopArticles{
        justify-content: center;
    }
}
@media screen and (max-width: 530px)  {
    .ShopTopArticles{
        justify-content: center;
    }
}