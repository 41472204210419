.menu{
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 90px;
}
.fixed_menu{
    position: fixed;
    z-index: 10;
    top: 0;
    left: 0;
    width: 100%;
    background-color: var(--white);
    box-shadow: 0px 5px 8px -7px rgb(225, 225, 225);
}
.menu_logo{
    width: 80px;
    height: 80px;
    background-image: url('./img/logo.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}
.cart_button{
    color: var(--green)!important;
}
.nav-link{
    color: var(--black)!important;
}
.btn-success{
    background-color: var(--green)!important;
    border: solid 1px var(--green)!important;
}
.modal .btn-primary{
    background-color: var(--green)!important;
    border: solid 1px var(--green)!important;
}
.menu_cart_icon{
    width: 1rem;
    height: 1rem;
    margin-right: 10px;
}
.fixed_menu .nav{
    min-width: 40%;
}
.menu_burger{
    display: none;
    width : 40px;
    height : 40px;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
}
.burger_line{
    width: 100%;
    height: 10%;
    background-color: var(--black);
    border-radius: 2px;
}
.burger_active .burger_line_1{
    transform: rotateZ(45deg);
    transition-duration: 500ms;
    transform-origin: left;
    margin-top: 15%;
}
.burger_active .burger_line_2{
    transform: scale(0);
    transition-duration: 500ms;
}
.burger_active .burger_line_3{
    transform: rotateZ(-45deg);
    transition-duration: 500ms;
    transform-origin: left;
    margin-top: 10%;
}
.phone_nav{
    position: fixed;
    width: 100%;
    height: calc(100vh - 90px);
    top: 90px;
    left: 0;
    display: flex;
    opacity: 0;
    pointer-events: none;
    background-color: var(--green);
    color: var(--white);
    border-top: solid 2px var(--green);
    transition: 0.4s;
}
.phone_nav li{
    display: inline-block;
    width: 100%;
    margin-top: 5vh;
    font-size: 2rem;
    font-family: "Poppins", sans-serif;
    text-align: center;
    color: var(--white);
}
.phone_nav_active{
    opacity: 1;
    pointer-events: all;
    transition: 0.4s;
}
.hidden{
    display: none!important;
}
@media screen and (max-width: 990px)  {
    .classic_nav{
        display: none!important;
    }
    .menu_burger{
        display: flex;
    }
}
