.who_we_are{
    margin-bottom: 10vh;
    min-height: 35rem;
}
.text_switch_bar{
    display: flex;
    margin-top: -6vh;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    height: auto;
}
.text_switch{
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
    width: 12vw;
    height: 12vw;
    background-color: var(--white);
    box-shadow: 0px 10px 13px -7px rgb(225, 225, 225);
    border: solid 1px var(--white);
    margin-bottom: 10vh;
    cursor: pointer;
    transition: 0.3s ease-out;
}
.text_switch:hover{
    box-shadow: 0px 5px 8px -7px rgb(225, 225, 225);
    background-color: var(--pal_gray);
    border: solid 1px var(--green);
    transition: 0.4s ease-out;
}
.text_switch_image{
    width: 6vh;
    height: 6vh;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
}
.text_switch_image_1{
    background-image: url('./img/icon1.png');
}
.text_switch_image_2{
    background-image: url('./img/icon2.png');
}
.text_switch_image_3{
    background-image: url('./img/icon3.png');
}
.text_switch_image_4{
    background-image: url('./img/icon4.png');
}
.text_switch h2{
    font-size: medium;
    font-weight: 400;
    color: var(--green);
}
.who_we_are_text h3{
    margin-bottom: 3rem;
}
@media screen and (max-width: 990px)  {
    .text_switch_bar{
        flex-wrap: wrap;
    }
    .text_switch{
        width : 35vw;
        height : 35vw;
        flex-wrap: nowrap;
    }
}