:root{
  --green : #86A083;
  --black : #1c1e1f;
  --dark-gray : #303335;
  --white : #FFF;
  --pal_gray : #F1F1F3;
}
.App {
  text-align: center;
  font-family: "Poppins", sans-serif;
}
a, a:hover, a:active {
  text-decoration: none;
  color: #000;
}