.cart_total{
    padding: 1rem 0.5rem;
    text-align: right;
    background-color: var(--pal_gray);
}
.modal{
    z-index: 10;
}
.cart_total span{
    font-weight: 600;
}