.cart_delivery{
    display: none;
}
.cart_delivery_desc{
    /* display: flex; */
    display : none;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 3rem 0;
}
.cart_delivery_desc .btn{
    display: inline-block;
    margin: 0.5rem 1rem;
}
.cart_map{
    display: none;
    background-color: #555;
    width: 100%;
    height: 25rem;
    margin: 1rem 0;
}
.cart_delivery_hour{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
}
.cart_delivery_hour .btn{
    flex: 1 0 21%;
    margin: 0.3rem 0.3rem;
}
.hidden{
    display: none;
}