.shop_article{
    width: 15rem;
    margin-bottom: 2vh;
}
.shop_article_image{
    height: 15rem;
    background-color: var(--pal_gray);
    object-fit: contain;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
}
.article_number_input{
    width: 30%;   
}
.buy_tool_bar{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.wish_add_button{
    font-family: "Poppins", sans-serif;
    display: none!important;
}