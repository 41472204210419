.shop_view{
    border-top: solid 3px var(--green);
}
.shop_view h3{
    display: block;
    background-color: var(--green);
    color: #FFF;
    font-size: 2rem;
    padding: 0.5rem;
    width: 25%;
    text-align: left;
    margin-top: -1.5rem;   
}
.shop_view_articles{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 3rem;
}
@media screen and (max-width: 770px)  {
    .shop_view h3{
        width: auto;
    }
}
@media screen and (max-width: 530px)  {
    .shop_view_articles{
        justify-content: center;
    }
}